export const fr = {
  home: "/",
  locale: "fr",
  localeNav: "/fr",
  archNav: "architecture",
  batNav: "batiment",
  devNav: "developpement",
  archTitle: "Architecture",
  batTitle: "Bâtiment",
  devTitle: "Développement web",
  devTitleNav: "Développement",
  comments: "Commentaires",
  iLike: "J'aime",
  comment: "Commenter",
  share: "Distribuer",
  discoverProject: "Découvrir le projet...",
  footer_p1: "Boogysh. Tous droits réservés.",
  footer_p2: "Mis à jour:",
  identity: "Identité",
  lastName: "Nom",
  firstName: "Prénom",
  birthDay: "Date de naissance",
  birthPlace: "Lieu de naissance",
  countryOfBirth: "Pays de naissance",
  country: "Moldavie",
  nationalities: "Nationalités",
  nationalities_val: "Moldave, Roumaine",
  ssNumber: "Numéro de S.S.",
  adress: "Addresse",
  tel: "Téléphone",
  email: "E-mail",
  civilStatus: "État civil",
  civilStatus_val: "Marié, deux Enfants.",
  studies: "Études et Diplômes",
  school: "Ecole Générale MERENI",
  bac: "Diplôme de Baccalauréat",
  college:
    "Université Technique de Moldavie, Faculté de l'Urbanisme et de l'Architecture Spécialité: Ingénierie urbaine",
  master: "Master: L'ingénierie des Constructions Civiles",
  master_stopped:
    "Interrompu, le profil ne correspondait pas au programme souhaité, trop éloigné de l' Architecture",
  archicad_3dsMax: "Formation AUTOCAD, ARCHICAD, 3DS MAX.",
  devWeb: "Développeur Web, Openclassrooms",
  devWeb_val: ">Diplôme de niveau 5 (bac +2)",
  experience: "Expérience",
  internship_1: "Stage de projection “IGC CONSTRUCT”",
  internship_2: "Stage préparation Diplôme “IGC CONSTRUCT” ",
  apprenticeship: "Apprentissage “IGC CONSTRUCT”",
  armanda: "Activité Non salariée Polyvalente : Société “Armanda” 75012 Paris",
  armanda_2: "Gérant : Société “Armanda” 75012 Paris",
  catalan: "Activité Non salariée Polyvalente Société “Catalan Building”",
  heres: "Société “Hères Construction”",
  heres_val: "Chef d'équipe, Chef de plusieurs équipes, Chef de Chantier",
  characteristics: "Caractéristiques",
  characteristics_val:
    " Responsabilité, Engagement, Ponctualité, Sociabilité, Aptitude de travailler avec les clients pour trouver une solution individuelle. Aptitude de travailler en équipe, rapide et efficace. Capacité d'évaluer les situations, afin de surmonter les obstacles et prendre des mesures (solutions) qui correspondent. Capacité et envie d'apprendre vite et de façon efficace de nouveaux domaines.",
  software: "Logiciels",
  advenced: "avancé",
  medium: "moyen",
  basic: "base",
  dev_Technologies: "Technos de développement",
  install: "Installation et Entretien",
  install_val: "WINDOWS, Logiciels et Equipements de bureau.",
  languages: "Langues",
  rom_mold: "Roumaine/Moldave",
  french: "Français",
  native: "natif",
  russian: "Russe",
  english: "Anglais",
  writtenExpression: "expression écrite (le code et le commentaire en englais)",
  driver_sLicense: "Permis de conduire",
  driverCategory: "Catégorie B",
  download: "Télécharger",

  cardArch: [
    {
      id: "pr3-stade",
      title: "Centre d'entrainement, destiné aux forces de l'ordre.",
      description:
        "C'est un projet destinné aux forces de l'ordre, équipé d'un terrain de foot pour 2300 spectateurs,un terrain de volley, un terrain de basket, une piscine de 25m, un cort de tennis, plusiers salles de box et combat, une salle de fitness etc.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-1.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-2.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-3.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-4.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-5.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-6.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-7.png",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-8.png",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-9.png",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-10.png",
      ],
      info: [
        "Stade: 2600 spectateurs.",
        "Espaces: Basket, Voley, Tennis, Box, Tir, Paintball, Fitness.",
        "Piscine: 25m, 6 couloirs.",
        "Repos: Sauna, Thérapie, Réstaurant.",
        "Parking: 360 voitures.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "pr1-maison",
      title: "Maison individuelle P+S (Rez de chaussée + mansarde).",
      description:
        "Maison pour une famille avec 3-4 enfants. Les photos sont des rendus (renders) realisées avec 3D'S MAX + V-ray.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-1.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-2.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-3.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-4.png",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-5.png",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-6.png",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-7.png",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-8.png",
      ],
      info: [
        "Maison en blocs de calcaires.",
        "Pour une famille de 5 - 6 personnes.",
        "Sous-sols: salle de sport et un dépôt.",
        "RDC: cuisine, sale à manger, séjour, bureau, bloc sanitaire.",
        "Étage: 4 chambres et bloc sanitaire. ",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "pr2-maison",
      title: "Maison, inspiré d'une conte de Fées.",
      description:
        "Cette maison avec des caractéristiques hors du commun reste assez connue. Le plus difficile est de systématiser l'espace pour la transformer dans un logement habitable.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-1.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-2.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-3.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-4.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-5.png",
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-6.png",
      ],
      info: [
        "Maison en blocs de calcaires.",
        "Pour une famille de 4 - 5 personnes.",
        "Projet connu,completement adaptaté au besoins du quotidien.",
        "RDC: Cuisine, salle à manger, séjour, WC.",
        "Étage: 3 chambres, SDB.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },

    {
      id: "pr4-cinema",
      title: "Boogysh Cinéma",
      description:
        "Le projet Boogysh Cinéma, répresente la solution aux problèmes comme: la physique du son, calcule de visibilité en respectant les règles de l'art.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr4/pr4-1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/arch/pr4/pr4-1.png",
        "https://boogysh.github.io/cv-api/images/arch/pr4/pr4-2.png",
        "https://boogysh.github.io/cv-api/images/arch/pr4/pr4-3.png",
        "https://boogysh.github.io/cv-api/images/arch/pr4/pr4-4.png",
      ],
      info: [
        "Salle de cinema, Longuer 24m, 5 secteurs.",
        "Capacité 528 places.",
        "Calcule de visibilité, assuré.",
        "Restaurants.",
        "Salles destinnées à l'administration.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "pr5-reconstruction",
      title: "La réconstruction d'un immeuble de 4 niveaux.",
      description:
        "La consolidation des fondations afin de rajouter un niveau et une mansarde.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-1.png",
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-2.png",
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-3.png",
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-4.png",
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-5.png",
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-6.png",
      ],
      info: [
        "Existent: Bâtiment en 4 niveaux, rez-de-chaussée + 3 étages.",
        "Rajout d'un étage supplémentaire et d'une mansarde.",
        "Calcule pour chaque type de fondation.",
        "Consolidation des fondations par tranche de 3m.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "pr6-urbanime",
      title: "Plan d'urbanisme",
      description:
        "Plan d'urbanisme en détails d'un cartier pour 5000 habitants",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr6/pr6-1.jpg",
      pictures: ["https://boogysh.github.io/cv-api/images/arch/pr6/pr6-1.jpg"],
      info: [
        "Quartier pour 5000 habitants.",
        "Une école maternelle.",
        "Éspaces aménagé: Pour les enfants 3-7 ans.",
        "Éspaces aménagé: Pour les enfants 7-12 ans.",
        "Éspaces verts.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "pr7-sistematisation-verticale",
      title: "Systématisation verticale",
      description:
        "Systématisation verticale d'un quartier avec l'artère routière adjacente",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr7/pr7-1.jpg",
      pictures: ["https://boogysh.github.io/cv-api/images/arch/pr7/pr7-1.jpg"],
      info: [
        "Identifier sur le plan  les courbes de niveau.",
        "D'après les courbes des niveaux, d'aménager un territoire avec des bâtiments et projeter une intersection dénivelée type trèfle allongé.",
        "Déterminer la cote '0' de chaque bâtiment.",
        "Montrer la section transversale de la  chossée. ",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
  ],
  cardBat: [
    {
      id: "pr1-terasse",
      title:
        "Réalisation d'une terasse en travertin et renovation de la piscine.",
      cover: "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-1.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-2.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-4.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-5.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-6.jpg",
      ],
      info: [
        "Réalisation d'une chappe de 6 à 12 cm.",
        "La pose du travertin ep. 2cm avec un  motif de 4 pieces. ",
        "La pose des margelles de la piscine ep. 5cm.",
        "Piscine - pose de la mosaïque 4.5cm x 4.5cm, colle epoxy. ",
        "Application des deux couches de protection sur la pierre.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "renovation_totale",
      title: "Rénovation totale d'un appartement",
      cover: "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-1.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-2.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-4.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-5.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-6.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-7.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-8.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-9.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-10.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-11.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-12.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-13.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-14.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-15.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-16.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-17.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-18.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-19.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-20.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-21.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-22.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-23.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-24.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-25.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-26.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-27.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-28.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-29.jpg",
      ],
      info: [
        "Démolition et évacuation des gravats.",
        "Réalisation l'ensemble des cloisons en placostil. ",
        "Installation des menuiseries.",
        "Electricité et plomberie",
        "Enduits et peinture",
        "Ragréage et chauffage au sol électrique",
        "Pause du carrelage dans la cuisine et les salles de bains.",
        "Pose collée du parquet massif dans les chambres.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "ouvertures",
      title: "Création des ouvertures",
      cover: "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-1.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-2.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-4.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-5.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-6.jpg",
      ],
      info: [
        "Calcule des charges des murs, plancher et de la charpante.",
        "Calcul et le dimensionnement des profils métalliques.",
        "Réalisation des linteau en deux étapes.",
        "Réalisation des jambages en béton armé.",
        "Réalisation de l'ouverture et évacuation des gravats.",
        "Réalisation d'un appui où seuil en béton armé.",
        "La pose de la menuiserie conforme DTU.",
        "Dégrossi et l'enduisage des tableaux.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "extension",
      title: "Réalisation d'une extension. Gros œuvre. ",
      cover: "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-1.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-4.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-5.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-6.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-7.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-8.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-9.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-10.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-11.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-12.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-13.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-14.jpg",
      ],
      info: [
        "Réalisation des fondations en béton armé profondeur 1.2m.",
        "Plancher RDC avec des hourdis en polystyrène et béton armé. ",
        "Montage des murs en parpaings de 20cm.",
        "Façade bâti avec de la maçonnerie traditionnelle.",
        "Charpente avec les raccords appropriés",
        "Chainnage et poteaux en béton armé.",
        "Le toit et la lucarne sont couverts avec des tuiles plates.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "isolation-placo",
      title: "Isolation des murs exterieurs",
      cover: "https://boogysh.github.io/cv-api/images/bat/pr5/pr5-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr5/pr5-1.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr5/pr5-2.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr5/pr5-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr5/pr5-4.jpg",
      ],
      info: [
        "Osature réalisée ave des rails et montants R48 et M48.",
        "Laine de verre GR32 avec l'épaisseur de 120mm.",
        "Membrane d'étanchéité.",
        "Placo BA13 normal/hydro/phonic/coupe feu.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "assainissement",
      title: "Assainissement et drainage",
      cover: "",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-1.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-2.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-4.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-5.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-6.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-7.png",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-8.png",
      ],
      info: [
        "Réalisation des tranchées  avec une pante 2cm au mètre.",
        "Encolage des tubes en PVC diametre 100mm pour chaque future évacuation.",
        "Raccord à l'extérieur de la maison dans un regard.",
        "Installation d'un regard 40x40 avec un tampon associé en béton. ",
        "Systeme de drainage avec 5 longueurs et une surface de 100 m.c. ",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
  ],
  cardDev: [
    {
      id: "pr12-fiche-eye",
      title: "Fiche-eye",
      cover: "https://boogysh.github.io/cv-api/images/dev/fiche-eye/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/fiche-eye/1.png",
        "https://boogysh.github.io/cv-api/images/dev/fiche-eye/2.png",
        "https://boogysh.github.io/cv-api/images/dev/fiche-eye/3.png",
        "https://boogysh.github.io/cv-api/images/dev/fiche-eye/4.png",
      ],
      info: [
        "Creez un site pour les phographes",
        "Liker les images dans le local storage",
        "Creation d'un slider",
        "Trier les photos par popularité, date où titre",
        "Completer et valider le formulaire de contact",
        "Envoyer le message sur la base de donnés mongoDB",

      ],
      technos: ["html", "css", "js","node","mongoDB"],
      urlProject: "https://fich-eye.vercel.app/",
    },
    {
      id: "pr11-moteur-recherche",
      title: "Créez un moteur de recherche",
      cover:
        "https://boogysh.github.io/cv-api/images/dev/moteur_recherche/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/moteur_recherche/1.png",
        "https://boogysh.github.io/cv-api/images/dev/moteur_recherche/2.png",
        "https://boogysh.github.io/cv-api/images/dev/moteur_recherche/3.png",
        "https://boogysh.github.io/cv-api/images/dev/moteur_recherche/4.png",
      ],
      info: [
        "Creez un moteur de recherche avec deux axes",
        "Creez des tags pour chaque valeur recherchée",
        "Chercher dans les ingredients, Appareils et Ustensiles",
        "Afficher l'intersection du resultat de recherche",
        "Afficher un message personalisé pour un resulatat nul",
      ],
      technos: ["html", "css", "js"],
      urlProject: "https://les-petits-plat.vercel.app/",
    },
    {
      id: "pr10-social-media",
      title: "Créez une application web type reseau social",
      cover: "https://boogysh.github.io/cv-api/images/dev/social-media/2.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/social-media/0.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/00.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/1.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/2.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/3.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/4.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/5.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/6.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/7.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/8.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/9.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/10.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/11.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/12.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/13.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/14.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/15.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/16.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/17.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/18.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/19.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/20.png",
      ],
      info: [
        "Utilisation du redux et react-redux, BD MongoDB et S3-AWS.",
        "Voir les visiteurs de la page de l'utilisateur.",
        "Dark-mode et le boutton recherche des utilisateurs enregistrés.",
        "Backend pour gérer les requêtes GET, POST, PATCH, DELETE",
        "Enregistrer et supprimer les ficiers sur S3 et les URL sur MongoDB.",
        "Afficher l'ensamble de l'aplication en ligne avec Vercel.",
      ],
      technos: ["react", "tailwind", "redux", "node", "mongoDB", "aws"],
      urlProject: "https://sm-boogysh.vercel.app/",
    },
    {
      id: "pr9-netflix-youtube",
      title: "Créez une application type netflix",
      cover: "https://boogysh.github.io/cv-api/images/dev/netflix/1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/netflix/1.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/2.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/3.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/4.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/5.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/6.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/7.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/8.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/9.jpg",
      ],
      info: [
        "Utilisation du redux et react-redux, BD MongoDB",
        "Backend pour gérer les requêtes GET, POST, PATCH, DELETE",
        "Intégrer l'api du themoviedb et youtube.",
        "Enregistrer les films dans les favorites",
        "Générer et voir le trailer du film de manière dynamique",
        "Afficher l'ensamble de l'aplication en ligne avec Vercel.",
      ],
      technos: [
        "react",
        "tailwind",
        "redux",
        "node",
        "mongoDB",
        "themoviedb",
        "youtube",
      ],
      urlProject: "https://boogysh-flix.vercel.app",
    },
    {
      id: "pr8-castle",
      title: "Créez une application web avec des éléments e-commerce",
      cover: "https://boogysh.github.io/cv-api/images/dev/kasa/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/castle/1.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/2.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/3.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/4.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/5.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/6.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/7.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/8.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/9.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/10.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/11.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/12.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/13.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/14.jpg",
      ],
      info: [
        "Utilisation du redux et react-redux.",
        "Réalisation d'un backend pour gérer les requêtes GET, POST.",
        "Utilisation de la BD MongoDB et mongoose.",
        "Envoyer les messages et les commandes sur la base de données.",
        "Recherche des commandes avec l'email où le numéro de la commande.",
        "Afficher l'ensamble de l'aplication en ligne avec Vercel.",
      ],
      technos: ["react", "css", "redux", "node", "mongoDB"],
      urlProject: "https://castle-boogysh.vercel.app/",
    },
    {
      id: "pr7-kasa",
      title: "Créez une application web de location immobilière avec React",
      cover: "https://boogysh.github.io/cv-api/images/dev/kasa/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/kasa/1.png",
        "https://boogysh.github.io/cv-api/images/dev/kasa/2.png",
        "https://boogysh.github.io/cv-api/images/dev/kasa/3.png",
        "https://boogysh.github.io/cv-api/images/dev/kasa/4.png",
        "https://boogysh.github.io/cv-api/images/dev/kasa/5.png",
        "https://boogysh.github.io/cv-api/images/dev/kasa/404.png",
      ],
      info: [
        "Découpage en composants modulaires et réutilisables.",
        "Configurer la navigation entre les pages avec React Router.",
        "Utilisation des props entre les composants.",
        "Utilisation du state dans les composants quand c'est nécessaire.",
        "Gestion des événements.",
        "La page 404 est renvoyée pour chaque route inexistante, ou il y'a une faute dans l'URL.",
      ],
      technos: ["react", "css"],
      urlProject: "https://boogysh.github.io/kasa/",
    },
    {
      id: "pr6-api-piquante",
      title:
        "Construisez une API sécurisée pour une application d'avis gastronomiques",
      cover: "https://boogysh.github.io/cv-api/images/dev/piquante/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/piquante/1.png",
        "https://boogysh.github.io/cv-api/images/dev/piquante/2.png",
        "https://boogysh.github.io/cv-api/images/dev/piquante/4.png",
        "https://boogysh.github.io/cv-api/images/dev/piquante/5.png",
        "https://boogysh.github.io/cv-api/images/dev/piquante/6.png",
        "https://boogysh.github.io/cv-api/images/dev/piquante/7.png",
      ],
      info: [
        "Toutes les routes dispose d'une autorisation type: Bearer <token>.",
        "Le mot de passe de l'utilisateur est haché.",
        "L'adresse électronique dans la base de données MongoDB est unique  (mongoose-unique-validator).",
        "L'utilisateur est vérifié si il est autorisé de modifier ou supprimer une sauce.",
        "La remontée des erreurs issues de la base de donnée, par des messages d'erreurs personnalisés.",
      ],
      technos: ["node", "mongoDB"],
      urlProject: "https://github.com/boogysh/piquante",
    },
    {
      id: "pr5-eCommerce",
      title: "Construisez un site e-commerce en JavaScript",
      cover: "https://boogysh.github.io/cv-api/images/dev/kanap/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-1.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-2.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-3.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-4.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-5.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-6.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-7.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-8.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-9.png",
      ],
      info: [
        "Gérer des événements JavaScript.",
        "Interagir avec un web service avec JavaScript.",
        "Valider des données issues de sources externes.",
        "Créer un plan de test pour une application.",
      ],
      technos: ["js"],
      urlProject: "https://github.com/boogysh/kanap",
    },
    {
      id: "pr4-SEO",
      title: "Optimisez un site web existant:",
      cover:
        "https://boogysh.github.io/cv-api/images/dev/lapanthere.fr/lapanthere-1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/lapanthere.fr/lapanthere-1.png",
        "https://boogysh.github.io/cv-api/images/dev/lapanthere.fr/lapanthere-2.png",
        "https://boogysh.github.io/cv-api/images/dev/lapanthere.fr/lapanthere-3.png",
        "https://boogysh.github.io/cv-api/images/dev/lapanthere.fr/lapanthere-4.png",
      ],
      info: [
        "Utiliser un domaine personalisé.",
        "Optimiser la taille et la vitesse d'un site web.",
        "Réaliser une recherche des bonnes pratiques en développement web.",
        "Écrire un code HTML et CSS maintenable.",
        "Assurer l'accessibilité d'un site web.",
        "Optimiser le référencement d'un site web.",
      ],
      technos: ["html", "css", "js"],
      urlProject: "https://la-panthere.fr/",
      urlExistent: "https://boogysh.github.io/la-panthere_initial/",
    },
    {
      id: "pr3-ohmyfood",
      title: "Dynamisez une page web avec des animations CSS",
      cover:
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-1.png",
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-2.png",
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-3.png",
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-4.png",
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-5.png",
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-6.png",
      ],
      info: [
        "Utiliser un système de gestion de versions pour le suivi du projet et son hébergement.",
        "Mettre en place son environnement frontend.",
        "Assurer la cohérence graphique d'un site web.",
        "Mettre en place une structure de navigation pour un site web.",
        "Mettre en œuvre des effets CSS graphiques avancés.",
      ],
      technos: ["html", "sass"],
      urlProject: "https://boogysh.github.io/OCR_PR3/",
    },
    {
      id: "pr2-booki",
      title: "Transformez une maquette en site web avec html et css",
      cover: "https://boogysh.github.io/cv-api/images/dev/booki/booki-1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/booki/booki-1.png",
        "https://boogysh.github.io/cv-api/images/dev/booki/booki-2.png",
        "https://boogysh.github.io/cv-api/images/dev/booki/booki-3.png",
        "https://boogysh.github.io/cv-api/images/dev/booki/booki-4.png",
        "https://boogysh.github.io/cv-api/images/dev/booki/booki-5.png",
      ],
      info: [
        "Découpage de la maquete.",
        "Réalisation de la page hébergements.",
        "Réalisation de la page activités.",
        "Utiliser des balises sémantiques.",
        "Réalisation de la partie responsive.",
        "Validation du code auprès du W3C.",
      ],
      technos: ["html", "css"],
      urlProject: "https://boogysh.github.io/booki/",
    },
  ],
};
