export const en = {
  home: "/",
  locale: "en",
  localeNav: "/en",
  archNav: "architecture",
  batNav: "building",
  devNav: "development",
  archTitle: "Architecture",
  batTitle: "Building",
  devTitle: "Development web",
  devTitleNav: "Development",
  comments: "Comments",
  iLike: "I Like",
  comment: "Comment",
  share: "Share",
  discoverProject: "Discover the project...",
  footer_p1: "Boogysh. All right reserved.",
  footer_p2: "Updated:",
  identity: "Identity",
  lastName: "Last Name",
  firstName: "First Name",
  birthDay: "Birth Day",
  birthPlace: "Birth Place",
  countryOfBirth: "Country of Birth",
  country: "R. of Moldova",
  nationalities: "Nationalities",
  nationalities_val: "Moldavian and Romanian",
  ssNumber: "S.S. Number",
  adress: "Address",
  tel: "Telephone",
  email: "E-mail",
  civilStatus: "Civil Status",
  civilStatus_val: "Married, two children.",
  studies: "Studies and Degrees",
  school: "General School MERENI",
  highSchool:
    "Real University Republican High School, Physics-Mathematics Profile",
  bac: "Bachelor's degree",
  college:
    "Technical University of Moldova, Faculty of Urbanism and Architecture Speciality:Engineering Urban Planning",
  master: "Master: Civil Construction Engineering",
  master_stopped:
    "Interrupted, the profile did not correspond to the desired program, too far from the Architecture",
  archicad_3dsMax: "Training AUTOCAD, ARCHICAD, 3DS MAX",
  devWeb: "Web Developer, Openclassrooms",
  devWeb_val: "Level 5 degree (bac +2)",
  experience: "Experience",
  internship_1: "Internship planning “IGC CONSTRUCT”",
  internship_2: "Internship preparation Degree “IGC CONSTRUCT”",
  apprenticeship: "Apprenticeship “IGC CONSTRUCT”",
  armanda: "Non-salaried activity : Company “Armanda”, 75012 Paris",
  armanda_2: "Director : Company “Armanda” 75012 Paris",
  catalan: "Non-salaried activity Multi-tasking Company “Catalan Building”.",
  heres: "Company “Hères Construction”",
  heres_val: "Team leader, many teams leader, Site manager",
  characteristics: "Characteristics",
  characteristics_val:
    "Responsibility, Commitment, Punctuality, Sociability, Ability to work with customers to find an individual solution. Ability to work in a team, fast and efficient. Ability to evaluate situations, to overcome obstacles and to take actions (solutions) that correspond. Ability and willingness to learn new areas quickly and efficiently.",
  software: "Software",
  advenced: "advenced",
  medium: "medium",
  basic: "basic",
  dev_Technologies: "Development technology",
  install: "Installation & Maintenance",
  install_val: "WINDOWS, Software and Office Equipment.",
  languages: "Languages",
  rom_mold: "Romanian/Moldavian",
  french: "French",
  native: "native",
  russian: "Russian",
  english: "English",
  writtenExpression: "written expression (code and commentary in English)",
  writtenComprehension:
    "written comprehension (average), oral comprehension (average), oral expression (beginner)",
  driver_sLicense: "Driver's license",
  driverCategory: "Category B",
  download: "Download",

  cardArch: [
    {
      id: "pr3-stade",
      title: "Training center for law enforcement officers",
      description:
        "It is a project for law enforcement officers, equipped with a soccer field for 2300 spectators, a volleyball court, a basketball court, a 25m swimming pool, a tennis court, several boxing and fighting rooms, a fitness room etc.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-1.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-2.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-3.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-4.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-5.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-6.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-7.png",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-8.png",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-9.png",
        "https://boogysh.github.io/cv-api/images/arch/pr3/pr3-stade-10.png",
      ],
      info: [
        "Stadium: 2600 spectators.",
        "Spaces: Basketball, Voley, Tennis, Box, Shooting, Paintball, Fitness.",
        "Swimming pool: 25m, 6 lanes.",
        "Rest: Sauna, Therapy, Restaurant.",
        "Parking: 360 cars.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "pr1-maison",
      title: "Single family house P+S (Ground floor + attic).",
      description:
        "Single family house (ground floor + attic) for a family with 3-4 children. The photos are renders made with 3D'S MAX + V-ray.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-1.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-2.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-3.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-4.png",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-5.png",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-6.png",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-7.png",
        "https://boogysh.github.io/cv-api/images/arch/pr1/pr1-8.png",
      ],
      info: [
        "Limestone block house.",
        "For a family of 5 - 6 persons.",
        "Basements: gym and storage room.",
        "Ground floor: kitchen, dining room, living room, office, toilet block.",
        "Floor: 4 bedrooms and sanitary block.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "pr2-maison",
      title: "The house, inspired by a fairy tale",
      description:
        "This house with unusual characteristics is still quite well known. The most difficult thing is to systematize the space in order to transform it into a habitable dwelling.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-1.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-2.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-3.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-4.jpg",
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-5.png",
        "https://boogysh.github.io/cv-api/images/arch/pr2/pr2-6.png",
      ],
      info: [
        "Limestone block house.",
        "For a family of 4 - 5 persons.",
        "Known project, completely adapted to the daily needs.",
        "Ground floor: Kitchen, dining room, living room, WC.",
        "Floor: 3 bedrooms, bathroom.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },

    {
      id: "pr4-cinema",
      title: "Boogysh Cinema",
      description:
        "The Boogysh Cinema project represents the solution to problems such as: sound physics, visibility calculation, respecting the rules of the art.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr4/pr4-1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/arch/pr4/pr4-1.png",
        "https://boogysh.github.io/cv-api/images/arch/pr4/pr4-2.png",
        "https://boogysh.github.io/cv-api/images/arch/pr4/pr4-3.png",
        "https://boogysh.github.io/cv-api/images/arch/pr4/pr4-4.png",
      ],
      info: [
        "Cinema room, 24m long, 5 sectors.",
        "Capacity 528 places.",
        "Visibility calculation, insured.",
        "Restaurants.",
        "Rooms for the administration.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "pr5-reconstruction",
      title: "Reconstruction of a 4 level building",
      description:
        "The consolidation of the foundations to add a level and a mansard.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-1.png",
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-2.png",
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-3.png",
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-4.png",
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-5.png",
        "https://boogysh.github.io/cv-api/images/arch/pr5/pr5-6.png",
      ],
      info: [
        "Existing: Building in 4 levels, first floor + 3 floors.",
        "Addition of an extra floor and a mansard.",
        "Calculates for each type of foundation.",
        "Foundation consolidation, each sector 3m length.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "pr6-urbanime",
      title: "Urban planning",
      description:
        "Detailed urban plan of a neighborhood for 5000 inhabitants.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr6/pr6-1.jpg",
      pictures: ["https://boogysh.github.io/cv-api/images/arch/pr6/pr6-1.jpg"],
      info: [
        "Area for 5000 inhabitants.",
        "A preschool.",
        "Landscaped areas: For children 3-7 years.",
        "Landscaped areas: For children 7-12 years.",
        "Green areas.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "pr7-sistematisation-verticale",
      title: "Vertical systematization",
      description:
        "Vertical systematization of a neighborhood with the adjacent arterial road.",
      cover: "https://boogysh.github.io/cv-api/images/arch/pr7/pr7-1.jpg",
      pictures: ["https://boogysh.github.io/cv-api/images/arch/pr7/pr7-1.jpg"],
      info: [
        "Identify level lines on the plan.",
        "According to the level lines, arrange a territory with buildings and project an elongated cloverleaf type intersection.",
        "Determine the level '0' of each building.",
        "Show the cross section of the road.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
  ],
  cardBat: [
    {
      id: "pr1-terasse",
      title: "Creation of a travertine terrace and renovation of the pool.",
      cover: "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-1.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-2.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-4.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-5.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr1/pr1-6.jpg",
      ],
      info: [
        "Realization of a 6 to 12 cm layer.",
        "The installation of travertine ep. 2cm with a pattern of 4 pieces.",
        "The installation of the pool border thickness 5cm.",
        "Swimming pool - installation of the mosaic 4.5cm x 4.5cm, epoxy glue.",
        "Application of the two layers of protection on the stone.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "renovation_totale",
      title: "Total renovation of an apartment",
      cover: "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-1.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-2.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-4.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-5.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-6.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-7.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-8.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-9.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-10.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-11.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-12.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-13.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-14.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-15.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-16.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-17.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-18.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-19.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-20.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-21.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-22.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-23.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-24.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-25.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-26.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-27.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-28.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr2/pr2-29.jpg",
      ],
      info: [
        "Demolition and evacuation of the rubble.",
        "Realization of all the partitions in Placostil.",
        "Installation of woodwork.",
        "Electricity and plumbing.",
        "Plastering and painting.",
        "Patch up and electric floor heating.",
        "Gluing of tiles in the kitchen and bathrooms.",
        "Glued installation of massif parquet in the rooms.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "ouvertures",
      title: "Creation of openings",
      cover: "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-1.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-2.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-4.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-5.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr3/pr3-6.jpg",
      ],
      info: [
        "Calculation of transmitted weight from walls, floor and roof.",
        "Calculation and dimensioning of metal profiles.",
        "Realization of the lintels in two stages.",
        "Realization of the jambs in reinforced concrete.",
        "Realization of the opening and evacuation of the rubble.",
        "Realization of a support or threshold in reinforced concrete.",
        "The installation of the joinery in accordance with DTU.",
        "Roughing and coating of the frames.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "extension",
      title: "Realization of an extension. Structural work.",
      cover: "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-1.jpg",

        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-4.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-5.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-6.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-7.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-8.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-9.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-10.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-11.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-12.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-13.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr4/pr4-14.jpg",
      ],
      info: [
        "Realization of the foundations in reinforced concrete depth 1.2m.",
        "Ground floor with polystyrene slabs and reinforced concrete.",
        "Construction of the walls in 20cm concrete blocks.",
        "Facade built with traditional masonry.",
        "Framing with appropriate fittings.",
        "Reinforced concrete band and columns.",
        "The roof and the skylight are covered with flat tiles.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "isolation-placo",
      title: "Insulation of exterior walls",
      cover: "https://boogysh.github.io/cv-api/images/bat/pr5/pr5-1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr5/pr5-1.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr5/pr5-2.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr5/pr5-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr5/pr5-4.jpg",
      ],
      info: [
        "Structure made with R48 and M48 rails and mounts.",
        "GR32 mineral wool with thickness of 120mm.",
        "Waterproofing membrane.",
        "Placo BA13 normal/hydro/phonic/fireproof.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
    {
      id: "assainissement",
      title: "Sanitation and drainage",
      cover: "",
      pictures: [
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-1.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-2.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-3.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-4.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-5.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-6.jpg",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-7.png",
        "https://boogysh.github.io/cv-api/images/bat/pr6/pr6-8.png",
      ],
      info: [
        "Realization of the trenches with a level difference 2cm for each meter.",
        "Gluing of PVC pipes diametre 100mm for each future evacuation.",
        "Connection outside of the house in a manhole.",
        "Installation of a 40x40 manhole with an associated concrete pad.",
        "Drainage system with 5 lengths and a surface of 100 sq.m.",
      ],
      technos: ["react", "tailwind", "node", "mongoDB"],
    },
  ],
  cardDev: [
    {
      id: "pr12-fiche-eye",
      title: "Fiche-eye",
      cover: "https://boogysh.github.io/cv-api/images/dev/fiche-eye/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/fiche-eye/1.png",
        "https://boogysh.github.io/cv-api/images/dev/fiche-eye/2.png",
        "https://boogysh.github.io/cv-api/images/dev/fiche-eye/3.png",
        "https://boogysh.github.io/cv-api/images/dev/fiche-eye/4.png",
      ],
      info: [
        "Create a site for phographers",
        "Liker images using the local storage",
        "Creating a slider",
        "Sort photos by popularity, date o",
        "Complete and validate the contact form",
        "Send the message to database mongoDB",
      ],
      technos: ["html", "css", "js", "node", "mongoDB"],
      urlProject: "https://fich-eye.vercel.app/",
    },
    {
      id: "pr11-moteur-recherche",
      title: "Create a search engine application",
      cover:
        "https://boogysh.github.io/cv-api/images/dev/moteur_recherche/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/moteur_recherche/1.png",
        "https://boogysh.github.io/cv-api/images/dev/moteur_recherche/2.png",
        "https://boogysh.github.io/cv-api/images/dev/moteur_recherche/3.png",
        "https://boogysh.github.io/cv-api/images/dev/moteur_recherche/4.png",
      ],
      info: [
        "Create a search engine with two axes",
        "Create tags for each searched value",
        "Search in Ingredients, Appliances and Utensils",
        "Show the intersection of search result",
        "Display a personalized message for a null result",
      ],
      technos: ["html", "css", "js"],
      urlProject: "https://les-petits-plat.vercel.app/",
    },
    {
      id: "pr10-social-media",
      title: "Create a social network web application",
      cover: "https://boogysh.github.io/cv-api/images/dev/social-media/2.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/social-media/0.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/00.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/1.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/2.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/3.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/4.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/5.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/6.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/7.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/8.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/9.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/10.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/11.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/12.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/13.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/14.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/15.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/16.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/17.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/18.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/19.png",
        "https://boogysh.github.io/cv-api/images/dev/social-media/20.png",
      ],
      info: [
        "Use of redux and react-redux, MongoDB and S3-AWS.",
        "View the visitors list of the user's page.",
        "Dark-mode and search button for registered users.",
        "Backend to manage requests GET, POST, PATCH, DELETE",
        "Save and delete files on S3-AWS and URLs on MongoDB.",
        "View the entire application online with Vercel.",
      ],
      technos: ["react", "tailwind", "redux", "node", "mongoDB", "aws"],
      urlProject: "https://sm-boogysh.vercel.app/",
    },
    {
      id: "pr9-netflix-youtube",
      title: "Créez une application type netflix",
      cover: "https://boogysh.github.io/cv-api/images/dev/netflix/1.jpg",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/netflix/1.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/2.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/3.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/4.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/5.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/6.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/7.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/8.jpg",
        "https://boogysh.github.io/cv-api/images/dev/netflix/9.jpg",
      ],
      info: [
        "Use of redux and react-redux, MongoDB.",
        "Backend to manage requests GET, POST, PATCH, DELETE",
        "Integrate themoviedb-api and youtube-api.",
        "Save movies to favorites.",
        "Generate and view the movie trailer dynamically.",
        "View the entire application online with Vercel.",
      ],
      technos: [
        "react",
        "tailwind",
        "redux",
        "node",
        "mongoDB",
        "themoviedb",
        "youtube",
      ],
      urlProject: "https://boogysh-flix.vercel.app",
    },
    {
      id: "pr8-castle",
      title: "Créez une application web avec des éléments e-commerce",
      cover: "https://boogysh.github.io/cv-api/images/dev/kasa/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/castle/1.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/2.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/3.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/4.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/5.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/6.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/7.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/8.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/9.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/10.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/11.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/12.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/13.jpg",
        "https://boogysh.github.io/cv-api/images/dev/castle/14.jpg",
      ],
      info: [
        "Utilisation du redux et react-redux.",
        "Réalisation d'un backend pour gérer les requêtes GET, POST.",
        "Utilisation de la BD MongoDB et mongoose.",
        "Envoyer les messages et les commandes sur la base de données.",
        "Recherche des commandes avec l'email où le numéro de la commande.",
        "Afficher l'ensamble de l'aplication en ligne avec Vercel.",
      ],
      technos: ["react", "css", "redux", "node", "mongoDB"],
      urlProject: "https://castle-boogysh.vercel.app/",
    },

    {
      id: "pr7-kasa",
      title: "Create a propery rental web application with React",
      cover: "https://boogysh.github.io/cv-api/images/dev/kasa/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/kasa/1.png",
        "https://boogysh.github.io/cv-api/images/dev/kasa/2.png",
        "https://boogysh.github.io/cv-api/images/dev/kasa/3.png",
        "https://boogysh.github.io/cv-api/images/dev/kasa/4.png",
        "https://boogysh.github.io/cv-api/images/dev/kasa/5.png",
        "https://boogysh.github.io/cv-api/images/dev/kasa/404.png",
      ],
      info: [
        "Cutting into modular and reusable components.",
        "Configure navigation between pages with React Router.",
        "Using the props between the components.",
        "Using the state in components when necessary.",
        "Event management.",
        "The 404 page is returned for each non-existent route, or there is a mistake in the URL.",
      ],
      technos: ["react", "css"],
      urlProject: "https://boogysh.github.io/kasa/",
    },
    {
      id: "pr6-api-piquante",
      title: "Build a secure API for a food review application",
      cover: "https://boogysh.github.io/cv-api/images/dev/piquante/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/piquante/1.png",
        "https://boogysh.github.io/cv-api/images/dev/piquante/2.png",
        "https://boogysh.github.io/cv-api/images/dev/piquante/4.png",
        "https://boogysh.github.io/cv-api/images/dev/piquante/5.png",
        "https://boogysh.github.io/cv-api/images/dev/piquante/6.png",
        "https://boogysh.github.io/cv-api/images/dev/piquante/7.png",
      ],
      info: [
        "All routes have a typical authorization: Bearer <token>.",
        "The user's password is hashed.",
        "The email address in the MongoDB database is unique (mongoose-unique-validator).",
        "The user is checked if he is allowed to modify or delete a sauce.",
        "Colect the errors from the database, through personalized error messages.",
      ],
      technos: ["node", "mongoDB"],
      urlProject: "https://github.com/boogysh/piquante",
    },
    {
      id: "pr5-eCommerce",
      title: "Build an e-commerce site in JavaScript",
      cover: "https://boogysh.github.io/cv-api/images/dev/kanap/1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-1.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-2.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-3.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-4.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-5.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-6.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-7.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-8.png",
        "https://boogysh.github.io/cv-api/images/dev/kanap/kanap-9.png",
      ],
      info: [
        "Managing JavaScript events.",
        "Interact with a web service with JavaScript.",
        "Validate data from external sources.",
        "Create a test plan for an application.",
      ],
      technos: ["js"],
      urlProject: "https://github.com/boogysh/kanap",
    },
    {
      id: "pr4-SEO",
      title: "Optimize an existing website:",
      cover:
        "https://boogysh.github.io/cv-api/images/dev/lapanthere.fr/lapanthere-1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/lapanthere.fr/lapanthere-1.png",
        "https://boogysh.github.io/cv-api/images/dev/lapanthere.fr/lapanthere-2.png",
        "https://boogysh.github.io/cv-api/images/dev/lapanthere.fr/lapanthere-3.png",
        "https://boogysh.github.io/cv-api/images/dev/lapanthere.fr/lapanthere-4.png",
      ],
      info: [
        "Use a custom domain.",
        "Optimize the size and speed of a website.",
        "Research the best practices in web development.",
        "Write maintainable HTML and CSS code.",
        "Ensure the accessibility of a website.",
        "Optimize the referencing of a website.",
      ],
      technos: ["html", "css", "js"],
      urlProject: "https://la-panthere.fr/",
      urlExistent: "https://boogysh.github.io/la-panthere_initial/",
    },
    {
      id: "pr3-ohmyfood",
      title: "Make a web page dynamic with CSS animations",
      cover:
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-1.png",
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-2.png",
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-3.png",
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-4.png",
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-5.png",
        "https://boogysh.github.io/cv-api/images/dev/ohmyfood/ohmyfood-6.png",
      ],
      info: [
        "Use a version control system for project versioning and hosting.",
        "Set up its frontend environment.",
        "Ensure the graphic consistency of a website.",
        "Set up a navigation structure for a website.",
        "Implement advanced graphic CSS effects.",
      ],
      technos: ["html", "sass"],
      urlProject: "https://boogysh.github.io/OCR_PR3/",
    },
    {
      id: "pr2-booki",
      title: "Turn a mock-up into a website with html and css",
      cover: "https://boogysh.github.io/cv-api/images/dev/booki/booki-1.png",
      pictures: [
        "https://boogysh.github.io/cv-api/images/dev/booki/booki-1.png",
        "https://boogysh.github.io/cv-api/images/dev/booki/booki-2.png",
        "https://boogysh.github.io/cv-api/images/dev/booki/booki-3.png",
        "https://boogysh.github.io/cv-api/images/dev/booki/booki-4.png",
        "https://boogysh.github.io/cv-api/images/dev/booki/booki-5.png",
      ],
      info: [
        "Cutting of the mock-up.",
        "Creation of the accommodation page.",
        "Creation of the activities page.",
        "Use semantic tags.",
        "Development of the responsive part.",
        "Validation of the code with the W3C.",
      ],
      technos: ["html", "css"],
      urlProject: "https://boogysh.github.io/booki/",
    },
  ],
};
